<template>
  <div class="ArbitrationMonitoring">
    <div class="search-box">
      <div>
        <template v-if="$route.query.examType == 1 && userInfo.roleId == 6">
          学校筛选：
          <el-select
            v-model="schoolId"
            placeholder="请选择学段"
            clearable
            @change="selectChange"
          >
            <el-option
              v-for="item in schoolList"
              :key="item.schoolId"
              :label="item.schoolName"
              :value="item.schoolId"
            >
            </el-option>
          </el-select>
        </template>
        题目：
        <el-select
          v-model="questionNum"
          placeholder="全部题目"
          @change="checkQuestion"
        >
          <template v-for="(item, i) in questionOrderList">
            <el-option
              v-if="item.ruleScore == 3 || item.ruleScore == 2"
              :key="i"
              :label="item.questionNum"
              :value="item.questionNum"
            >
            </el-option>
          </template>
        </el-select>
        阅卷老师：
        <el-select
          v-model="teacherId"
          placeholder="所有老师"
          @change="checkList()"
        >
          <el-option
            v-for="(item, i) in teacherList"
            :key="i"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        分数：
        <el-input
          v-model="score"
          placeholder=""
          oninput="value=value.replace(/[^0-9.]/g,'')"
          @blur="score = $event.target.value"
          @keyup.enter.native="arbitration"
        ></el-input
        >分（满分{{ indexQuestion.score }}分）
        <el-button class="set-btn" type="primary" plain @click="arbitration"
          >搜索</el-button
        >
      </div>
    </div>
    <el-table
      v-loading="listLoading"
      :data="qualityList"
      border
      style="width: 100%"
    >
      <el-table-column prop="teacherName" label="仲裁教师" align="center">
      </el-table-column>
      <el-table-column
        prop="rowNum"
        label="批阅顺序"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column prop="createTime" label="批阅时间" align="center">
      </el-table-column>
      <el-table-column prop="score" label="给分" align="center" width="60">
      </el-table-column>

      <el-table-column
        prop="againScore"
        label="重评给分"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.againScore">
            {{ scope.row.againScore }}
          </template>
          <template v-else>-</template>
        </template>
      </el-table-column>
      <el-table-column
        prop="changeScore"
        label="改分"
        align="center"
        width="60"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.changeScore">
            {{ scope.row.changeScore }}
          </template>
          <template v-else>-</template>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="处理结果" align="center" width="100">
        <template slot-scope="scope">
          <template v-if="!scope.row.state"> 未抽查 </template>
          <template v-if="scope.row.state == 1"> 通过 </template>
          <template v-if="scope.row.state == 2"> 打回 </template>
          <template v-if="scope.row.state == 3"> 改分 </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="extractTime"
        label="抽查时间"
        align="center"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column prop="extractUserName" label="抽查人员" align="center">
      </el-table-column>
      <el-table-column prop="extractRate" label="抽查" align="center">
        <template v-if="!scope.row.state" slot-scope="scope">
          <el-button type="text" @click="showPaper(scope.row)">抽查</el-button>
        </template>
        <template v-else>-</template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getObj } from "@/core/api/exam/exam";
import {
  arbitration,
  // getQuestionTeacher,
  multipleCondition,
  questionTeacher,
} from "@/core/api/exam/paperMonitor";
import { showSubject } from "@/core/util/util";
import { getStore, setStore } from "@/core/util/store";
import { mapGetters } from "vuex";
export default {
  name: "ArbitrationMonitoring",
  data() {
    return {
      pageIndex: 1,
      pageSize: 50,
      total: 0,
      listLoading: false,
      score: "",
      examPaperId: "",
      questionNum: "",
      teacherId: "",
      teacherIdkey: "",
      paperList: [],
      teacherList: [],
      questionOrderList: [],
      indexTeacher: {},
      indexQuestion: {},
      qualityList: [],
      schoolList: [],
      schoolId: "",
    };
  },
  computed: {
    ...mapGetters(["GET_SCHOOL_LIST"]),
  },
  watch: {
    GET_SCHOOL_LIST: {
      deep: true,
      handler() {
        this.schoolList = this.GET_SCHOOL_LIST;
        if (this.schoolList.length > 0) {
          this.schoolId = "-1";
        }
      },
    },
  },
  created() {
    this.userInfo = getStore({ name: "userInfo" });
    this.schoolList = this.GET_SCHOOL_LIST;
    if (this.schoolList.length > 0) {
      this.schoolId = "-1";
    }
    this.examPaperId = Number(this.$route.query.examPaperId);
    this.multipleCondition(1);
    // this.questionTeacher(2);
    this.getExamInfo();
    // this.arbitration();
  },
  methods: {
    setSearchList() {
      let pageInfo = getStore({ name: "ArbitrationMonitoring_info" });
      // console.log(pageInfo);
      // console.log(pageInfo.questionNum);
      if (
        pageInfo &&
        pageInfo.questionNum &&
        this.examPaperId == pageInfo.examPaperId
      ) {
        this.questionNum = pageInfo.questionNum;
        this.score = pageInfo.score;
        this.pageIndex = pageInfo.current;
        this.pageSize = pageInfo.size;
      }
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.arbitration();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.arbitration();
      // console.log(`当前页: ${val}`);
    },
    selectChange() {
      this.pageIndex = 1;
      this.arbitration();
    },
    showSubject(v) {
      return showSubject(v, 8);
    },
    questionTeacher() {
      let data = {
        examPaperId: this.examPaperId,
        questionNum: this.questionNum,
      };
      let pageInfo = getStore({ name: "ArbitrationMonitoring_info" });
      questionTeacher(data).then((res) => {
        this.teacherList = [{ label: "所有老师", value: "-1" }];

        res.data.data.forEach((item) => {
          if (
            pageInfo &&
            pageInfo.examPaperId == this.examPaperId &&
            pageInfo.teacherId == item.id
          ) {
            this.teacherId = pageInfo.teacherId;
          }
          this.teacherList.push({
            label: item.name,
            value: item.id,
          });
        });
        //
        this.teacherId = this.teacherId || "-1";
      });
    },
    // 获取题目列表
    multipleCondition() {
      let data = {
        examPaperId: this.examPaperId,
        // type: type,
      };
      multipleCondition(data).then((res) => {
        this.questionOrderList = res.data.data;
        if (this.questionOrderList.length > 0) {
          this.questionNum =
            this.questionNum || this.questionOrderList[0].questionNum;
          this.questionTeacher();
          this.arbitration();
        }
      });
    },
    showPaper(item) {
      let data = {
        id: item.id,
        examPaperId: item.examPaperId,
        questionNum: item.questionNum,
        teacherId: item.teacherId,
      };
      // console.log(item);
      this.$router.push({
        name: "ShowCheckPaper",
        query: data,
      });
    },
    // 获取仲裁监控列表
    arbitration() {
      let data = {
        examPaperId: this.examPaperId,
        questionNum: this.questionNum,
        score: this.score,
        teacherId: this.teacherId || "-1",
        current: this.pageIndex,
        size: this.pageSize,
      };
      if (this.$route.query.examType == 1) {
        data.schoolId = this.schoolId || "-1";
      }
      if (this.userInfo.roleId != 6) {
        data.schoolId = getStore({ name: "school_id" });
      }
      setStore({ name: "ArbitrationMonitoring_info", content: data, type: 1 });
      this.listLoading = true;
      arbitration(data)
        .then((res) => {
          this.qualityList = res.data.data.records;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    checkList() {
      // this.indexTeacher = this.teacherList.filter(
      //   (item) => item.key == this.teacherIdkey
      // )[0];
      this.arbitration();
    },
    checkQuestion() {
      this.indexQuestion = this.questionOrderList.filter(
        (item) => item.questionNum == this.questionNum
      )[0];
      this.questionTeacher();
      this.arbitration();
    },
    checkSubject() {
      this.questionNum = "";
      this.indexTeacher = {};
      this.indexQuestion = {};
      this.multipleCondition(1);
      this.questionTeacher();
      this.arbitration();
    },

    getExamInfo() {
      getObj(this.$route.query.examId).then((res) => {
        // this.examInformation = res.data.data;
        res.data.data.paperList.map((item) => {
          this.paperList.push({
            value: item.paperId,
            label: item.subjectName,
            subjectId: item.subjectId,
          });
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ArbitrationMonitoring {
  padding: 24px;
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .el-input {
      width: 80px;
      margin-right: 8px;
    }
    .el-select {
      margin: 0 24px 0 8px;
    }
    .subject-name {
      width: 120px;
    }
  }
}
</style>
